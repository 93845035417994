.login-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(56, 71, 88, 0.9);
  width: 100%;
  height: 100vh;
}

.login-logo {
  margin-top: 10%;
}
.login {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 260px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(54, 66, 79, 0.41);
  background-color: white;
  padding: 50px 60px 30px 60px;
}

.login__title {
  font-size: 24px;
  color: #36424f;
}

.login__form-control {
  display: block;
  border-radius: 5px;
  border: solid 1px #384758;
  background-color: white;
  background-clip: padding-box;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #384758;
  margin-top: 10px;
}

.login__button {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 40px;
  border-radius: 5px;
  background-color: #5bd9f9;
  cursor: pointer;
}

.login__error {
  color: red;
  margin-top: 10px;
}
