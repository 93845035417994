.dashboard-chart {
    box-shadow: 0 1px 2px 0 rgba(56, 71, 88, 0.2);
    background-color: #ebf1f4;
}

.dashboard-chart__title {
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    justify-content: flex-start;
    box-shadow: inset 0 -1px 0 0 #dce3eb;
    background-color: #f9fbfc;
}
