.channel-table {
  margin: 30px;
  height: calc(100vh - 390px);
}

.daypart__cell {
  display: flex;
}
.daypart__logo {
  width: 26px;
  height: 26px;
  background-color: #ff827a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  margin-right: 15px;
}

.daypart-table__footer {
  height: 50px;
  border-radius: 10px;
  background-color: #384758;
  color: white;
}

.daypart-table__footer td {
  height: 50px;
}

.daypart-table__footer td:first-child {
  padding-left: 15px;
}

.daypart-table__footer td:last-child {
  padding-right: 15px;
}

.total-img {
  margin-right: 17px;
}
