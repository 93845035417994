.export-report {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(91, 217, 249, 0.2);
    background-color: #5bd9f9;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.export-report__icon {
    margin-right: 10px;
}
