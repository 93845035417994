.account-settings {
  padding: 25px 30px;
}

.settings-panel {
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(56, 71, 88, 0.2);
  background-color: white;
  padding: 25px 20px;
}

.settings-panel:not(:first-child) {
  margin-top: 20px;
}

.settings-panel__title {
  font-size: 16px;
  font-weight: bold;
  color: #384758;
}

.settings-panel__description {
  font-size: 12px;
  color: #384758;
}

.notification-settings {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.account-settings__button-panel {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}
