.notifications__background {
  background-color: rgba(12, 12, 12, 0.2);
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.notifications {
  position: absolute;
  height: 100vh;
  width: 290px;
  overflow-x: hidden;
  transition: 0.5s;
  right: 0;
  box-shadow: 0 1px 4px 0 rgba(54, 66, 79, 0.25),
    0 2px 20px 0 rgba(54, 66, 79, 0.35);
  background-color: white;
  overflow-y: hidden;
}

.notifications__header {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #384758;

  font-size: 16px;
  font-weight: bold;
  color: white;
  padding-left: 30px;
  padding-right: 20px;
}

.notifications__title {
  margin-left: 6px;
}

.notifications__hide {
  margin-left: auto;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.notifications__list {
  overflow-y: auto;
  height: calc(100vh - 100px);
  padding-top: 10px;
}
