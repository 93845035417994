.daypart-table {
    margin: 30px;
    height: calc(100vh - 390px);
}

.daypart-selector {

    margin-right: 30px;
    margin-left: 30px;
    padding: 10px;
    border-bottom: solid 1px #dce3eb;
}

.daypart-selector__option {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    border: solid 1px #dce3eb;
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.daypart-selector__option:not(:first-child) {
    margin-left: 10px;
}

.daypart-selector__option--active,
.daypart-selector__option:hover {
    padding-left: 14px;
    padding-right: 14px;
    border: solid 2px rgba(91, 217, 249, 0.5);
}

.daypart-selector__option--active {
    font-weight: bold;
}
