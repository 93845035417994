.data-selector {
    display: flex;
}

.select-market {
    min-width: 200px;
}

.select-media {
    min-width: 150px;
    margin-left: 10px;
}

.select-data {
    min-width: 100px;
    margin-left: 10px;
}
