.week-selector {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 5px;
  border: solid 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0 10px 0 0;
  background-color: rgba(255, 255, 255, 0.1);
  user-select: none;
}

.week-selector--active,
.week-selector:hover {
  border: solid 2px rgba(91, 217, 249, 0.5);
}

.week-number {
  color: white;
  font-size: 16px;
  font-weight: bold;
  min-height: 1.25rem;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 66px;
}

.week-range {
  display: flex;
  align-items: center;
  font-size: 16px;
  min-height: 1.25rem;
  color: white;
  padding: 10px;
  min-width: 215px;
}

.week-range-arrow {
  width: 20px;
  height: 20px;
}

.week-range-dropdown {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(54, 66, 79, 0.15),
    0 2px 3px 0 rgba(56, 71, 88, 0.18);
  background-color: white;
  z-index: 10;
}

.week-range-dropdown-item {
  display: flex;
  height: 40px;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #e3e7eb;
  user-select: none;
}
.week-range-dropdown-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.week-range-dropdown-item--active {
  background-image: url("images/tick.svg");
  background-repeat: no-repeat;
  background-position: 97% center;
}

.week-range-dropdown-item:hover {
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  background-color: #f9fbfc;
}

.week-range-dropdown-item__week {
  padding: 0 15px;
  min-width: 66px;
}

.week-range-dropdown-item__range {
  border-left: solid 1px #dce3eb;
  text-align: center;
  padding: 10px;
}

.week-select {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.week-start,
.week-end {
  display: inline-block;
  min-width: 100px;
  text-align: right;
  padding-right: 5px;
  padding-left: 5px;
}
