.chart-panel {
  display: flex;
  flex-direction: column;
  height: 390px;
  width: 100%;

  min-width: 204px;
  border-radius: 10px;
  border: solid 1px #dce3eb;
  background-color: white;
}

.chart-panel:not(:first-child) {
  margin-left: 20px;
}

.chart-panel__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 50px;
  flex-shrink: 0;

  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  background-color: #f9fbfc;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;

  font-family: Nunito, serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #384758;
}

