.product-chart {
    background-color: #f9fbfc;
    border-radius: 10px;
    box-shadow: inset 0 -1px 0 0 #dce3eb;
    margin-left: 30px;
    margin-right: 30px;
}

.product-chart__title {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: -20px 0 0 -20px;
}

.chart-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88px;
    min-width: 204px;
    border-radius: 10px;
    border: solid 1px #dce3eb;
    background-color: white;
}

.chart-box:not(:first-child) {
    margin-left: 20px;
}
