.breadcrumbs {
    display: flex;
    padding: 25px 30px;
}

.delimiter {
    margin-left: 10px;
    margin-right: 10px;
}

.breadcrumbs__item {
    cursor: pointer;
    text-decoration: none;
}
