.toggle {
    display: flex;
    margin-left: 10px;
    border: solid 1px #dce3eb;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.toggle__chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.toggle__table {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.toggle__item--active {
    background-color: #5bd9f9;
}
