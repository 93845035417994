@import url(https://fonts.googleapis.com/css?family=Nunito:400,400i,700,700i&display=swap);
body {
  margin: 0;
  font-family: 'Nunito', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  display: -webkit-flex;
  display: flex;
  height: 70px;
  background-color: #384758;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 30px;
}

.header__week-selector {
  margin-left: auto;
}

.notifications-toggle {
  margin-left: 20px;
  cursor: pointer;
}

.header__logout {
  margin-left: 20px;
  margin-bottom: 7px;
  cursor: pointer;
}

.profile {
  margin-left: 10px;
}

.client-selector {
  margin-left: 2rem;
  min-width: 400px;
}

.header__admin {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10px;
  text-decoration: none;
  height: 26px;
  width: 26px;
  background-color: #5BD9F9;
  border-radius: 50%;
  color: #384758;
  font-family: Nunito, serif;
  font-size: 12px;
  font-weight: bold;
  margin-top: -6px;
}

.week-selector {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  border: solid 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0 10px 0 0;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.week-selector--active,
.week-selector:hover {
  border: solid 2px rgba(91, 217, 249, 0.5);
}

.week-number {
  color: white;
  font-size: 16px;
  font-weight: bold;
  min-height: 1.25rem;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 66px;
}

.week-range {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  min-height: 1.25rem;
  color: white;
  padding: 10px;
  min-width: 215px;
}

.week-range-arrow {
  width: 20px;
  height: 20px;
}

.week-range-dropdown {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(54, 66, 79, 0.15),
    0 2px 3px 0 rgba(56, 71, 88, 0.18);
  background-color: white;
  z-index: 10;
}

.week-range-dropdown-item {
  display: -webkit-flex;
  display: flex;
  height: 40px;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: inset 0 -1px 0 0 #e3e7eb;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.week-range-dropdown-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.week-range-dropdown-item--active {
  background-image: url(/static/media/tick.ec343207.svg);
  background-repeat: no-repeat;
  background-position: 97% center;
}

.week-range-dropdown-item:hover {
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  background-color: #f9fbfc;
}

.week-range-dropdown-item__week {
  padding: 0 15px;
  min-width: 66px;
}

.week-range-dropdown-item__range {
  border-left: solid 1px #dce3eb;
  text-align: center;
  padding: 10px;
}

.week-select {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.week-start,
.week-end {
  display: inline-block;
  min-width: 100px;
  text-align: right;
  padding-right: 5px;
  padding-left: 5px;
}

.c-dropdown {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    border: solid 2px #dce3eb;
    background-color: white;
    padding: 10px;
    color: black;
}

.c-dropdown--active,
.c-dropdown:hover {
    border: solid 2px rgba(91, 217, 249, 0.5);
}

.c-dropdown__arrow {
    margin-left: auto;
    width: 20px;
    height: 20px;
}

.breadcrumbs {
    display: -webkit-flex;
    display: flex;
    padding: 25px 30px;
}

.delimiter {
    margin-left: 10px;
    margin-right: 10px;
}

.breadcrumbs__item {
    cursor: pointer;
    text-decoration: none;
}

.footer {
  display: -webkit-flex;
  display: flex;
  height: 45px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  padding-top: 9px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-top: 1px solid #dce3eb;
  font-size: 12px;
  color: #384758;
}

.product-chart {
    background-color: #f9fbfc;
    border-radius: 10px;
    box-shadow: inset 0 -1px 0 0 #dce3eb;
    margin-left: 30px;
    margin-right: 30px;
}

.product-chart__title {
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: -20px 0 0 -20px;
}

.chart-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 88px;
    min-width: 204px;
    border-radius: 10px;
    border: solid 1px #dce3eb;
    background-color: white;
}

.chart-box:not(:first-child) {
    margin-left: 20px;
}

.chart-tab {
  height: 68px;
  min-width: 102px;
  border-radius: 10px;
  border: solid 1px #dce3eb;
  background-color: white;
  padding: 10px 15px;
  cursor: pointer;
  margin: 20px 0 0 20px;
}

.chart-tab--selected {
  border: solid 2px #5bd9f9;
}

.chart-tab__name {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #384758;
}

.chart-tab__amount {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  color: #384758;
}

.chart-tab__diff {
  display: -webkit-flex;
  display: flex;

}

.chart-tab__arrow {
    min-width: 10px
}
.chart-tab__persent {
    font-size: 12px;
    color: #384758;
    margin-left: 5px;
}

.chart-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 390px;
  width: 100%;

  min-width: 204px;
  border-radius: 10px;
  border: solid 1px #dce3eb;
  background-color: white;
}

.chart-panel:not(:first-child) {
  margin-left: 20px;
}

.chart-panel__title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-basis: 50px;
          flex-basis: 50px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;

  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  background-color: #f9fbfc;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;

  font-family: Nunito, serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #384758;
}


.dayparts-chart {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    padding: 15px 25px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.dayparts-chart__list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-basis: 180px;
            flex-basis: 180px;
}

.daypart-row {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.daypart-row__point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.daypart-row__title {
    margin-left: 10px;
}

.daypart-row__percent {
    margin-left: auto;
}

.dayparts-chart__chart {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-width: 0;
}

.ag-theme-balham .ag-root {
  border: none !important;
  border-radius: 5px;
}

.product-table {
  margin: 40px 30px;
  border-collapse: collapse;
  font-family: Nunito, sans-serif;
  height: calc(100vh - 390px);
}

.ag-header-cell {
  background-color: #f9fbfc;
}

.ag-header-cell-label .ag-header-cell-text{
  border-spacing: 0;
  font-size: 12px;
  color: #384758;
  text-transform: uppercase;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  border-collapse: collapse;
}

.selected-column {
  font-weight: bold;
  color: #384758;
}

.ag-row .ag-cell {
  display: -webkit-flex;
  display: flex;
  /*justify-content: center; /* align horizontal */
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
}

.ag-row-hover {
  box-shadow: 0 2px 4px 0 rgba(54, 66, 79, 0.1) !important;
  border: solid 2px rgba(91, 217, 249, 0.5) !important;
  background-color: white !important;
}

.product-table__station {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.product-table__centered-column {
  text-align: center;
}

.product-table__right {
  text-align: right;
}

.product-table__sorter {
  margin-left: 5px;
}

.product-table__row {
  background-color: white;
}

.product-table__head td:first-child,
.product-table__row td:first-child {
  padding-left: 15px;
}

.product-table__head td:last-child,
.product-table__row td:last-child {
  padding-right: 15px;
}

.product-table__row:nth-of-type(even) {
  background: #f9fbfc;
}

.product-table__row td {
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px rgba(91, 217, 249, 0.5);
}

.product-table__navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 30px;
  font-size: 12px;
  color: #384758;
}

.rows-per-page {
  display: -webkit-flex;
  display: flex;
}

.rows-per-page__button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border-radius: 5px;
  border: solid 1px #dce3eb;
  background-color: white;
  cursor: pointer;
}

.rows-per-page__button--active {
  border: none;
  color: white;
  background-color: #5bd9f9;
}

.rows-per-page__button:not(:first-child) {
  margin-left: 6px;
}

.rows-per-page__text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 3px;
}

.showing-entries__numbers {
  font-weight: bold;
}

.page {
  display: -webkit-flex;
  display: flex;
}

.page__text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}


.channel-table {
  margin: 30px;
  height: calc(100vh - 390px);
}

.daypart__cell {
  display: -webkit-flex;
  display: flex;
}
.daypart__logo {
  width: 26px;
  height: 26px;
  background-color: #ff827a;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  font-size: 12px;
  margin-right: 15px;
}

.daypart-table__footer {
  height: 50px;
  border-radius: 10px;
  background-color: #384758;
  color: white;
}

.daypart-table__footer td {
  height: 50px;
}

.daypart-table__footer td:first-child {
  padding-left: 15px;
}

.daypart-table__footer td:last-child {
  padding-right: 15px;
}

.total-img {
  margin-right: 17px;
}

.daypart-table {
    margin: 30px;
    height: calc(100vh - 390px);
}

.daypart-selector {

    margin-right: 30px;
    margin-left: 30px;
    padding: 10px;
    border-bottom: solid 1px #dce3eb;
}

.daypart-selector__option {
    box-sizing: border-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    height: 40px;
    border-radius: 10px;
    border: solid 1px #dce3eb;
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.daypart-selector__option:not(:first-child) {
    margin-left: 10px;
}

.daypart-selector__option--active,
.daypart-selector__option:hover {
    padding-left: 14px;
    padding-right: 14px;
    border: solid 2px rgba(91, 217, 249, 0.5);
}

.daypart-selector__option--active {
    font-weight: bold;
}

.data-selector {
    display: -webkit-flex;
    display: flex;
}

.select-market {
    min-width: 200px;
}

.select-media {
    min-width: 150px;
    margin-left: 10px;
}

.select-data {
    min-width: 100px;
    margin-left: 10px;
}

.toggle {
    display: -webkit-flex;
    display: flex;
    margin-left: 10px;
    border: solid 1px #dce3eb;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.toggle__chart {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 40px;
    height: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.toggle__table {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 40px;
    height: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.toggle__item--active {
    background-color: #5bd9f9;
}

.title_bar__toggle {
  margin-left: auto;
}

.export-report {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-left: auto;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(91, 217, 249, 0.2);
    background-color: #5bd9f9;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.export-report__icon {
    margin-right: 10px;
}

.app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #ebf1f4;
  min-height: 100vh;
  position: relative;
}

.router {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #ebf1f4;
  /* min-height: 100vh;*/
}
.title-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}


.app__product_chart {
  margin-top: 30px;
}
.chart-row {
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.chart-column {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  min-width: 512px;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.form-row {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
}

.form-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-top: auto;
}

.form-group--no-grow {
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

.form-row__form-group {
  margin-left: 35px;
}

.form-label {
  font-size: 12px;
  color: #384758;
}

.form-error {
  font-size: 14px;
  color: red;
}
.form-control {
  display: block;
  border-radius: 5px;
  border: solid 1px #384758;
  background-color: white;
  background-clip: padding-box;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #384758;
  margin-top: 5px;
}

.form-control::-webkit-input-placeholder {
  color: #a0b4c8;
}

.form-control:-ms-input-placeholder {
  color: #a0b4c8;
}

.form-control::placeholder {
  color: #a0b4c8;
}

.app-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 40px;
  width: 250px;
  border-radius: 5px;
  background-color: #5bd9f9;
  cursor: pointer;
  text-decoration: none;
}



.dashboard-title {
    display: -webkit-flex;
    display: flex;
}
.dashboard-title__logo {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: white;
}

.dashboard-title__container {
    margin-left: 20px;
}

.dashboard-title__client-name {
    font-size: 44px;
    font-weight: bold;
    color: #384758;
}

.dashboard-title__page-name {
    font-size: 16px;
    color: #384758;
    padding-left: 0.25rem;
}

.dashboard-chart {
    box-shadow: 0 1px 2px 0 rgba(56, 71, 88, 0.2);
    background-color: #ebf1f4;
}

.dashboard-chart__title {
    display: -webkit-flex;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    box-shadow: inset 0 -1px 0 0 #dce3eb;
    background-color: #f9fbfc;
}

.dashboard {
    padding: 30px 30px 0;
}

.dashboard__dashboard-chart {
    margin-top: 30px;
}

.dashboard__products {
    padding-top: 50px;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    color: #384758;
}

.dashboard-products {
  width: 100%;
  padding-top: 50px;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(56, 71, 88, 0.2);
  background-color: white;
}

.dashboard-products__head {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  background-color: #f9fbfc;
  text-transform: uppercase;
  font-size: 12px;
  color: #384758;
}

.dashboard-products__product-title,
.dashboard-products__title-col,
.dashboard-products__spend-title {
  padding: 10px 15px;
}

.dashboard-products__title-col,
.dashboard-products__spend-title {
  text-align: right;
}

.dashboard-products__spend-title {
  font-weight: bold;
}

.dashboard-products__row {
  height: 72px;
  cursor: pointer;
}

.dashboard-products__row:nth-of-type(even) {
  background: #f9fbfc;
}

.dashboard-products__row:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dashboard-products__row td {
  width: 25%;
}

.dashboard-products__product-col {
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  font-size: 16px;
  color: #384758;
}

.dashboard-products__spend-col,
.dashboard-products__revenue-col,
.dashboard-products__cpv-col {
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  font-size: 24px;
  text-align: right;
  color: #384758;
}

.dashboard-products__spend-col {
  font-weight: bold;
}

.account-settings {
  padding: 25px 30px;
}

.settings-panel {
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(56, 71, 88, 0.2);
  background-color: white;
  padding: 25px 20px;
}

.settings-panel:not(:first-child) {
  margin-top: 20px;
}

.settings-panel__title {
  font-size: 16px;
  font-weight: bold;
  color: #384758;
}

.settings-panel__description {
  font-size: 12px;
  color: #384758;
}

.notification-settings {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
}

.account-settings__button-panel {
  margin-top: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.switch {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.switch__text-box {

}

.switch__title {
  font-size: 16px;
  color: #384758;
}

.switch__description {
  font-size: 12px;
  color: #a0b4c8;
}

.switch__control {
  margin-left: 20px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 30px;
}

.switch__slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.switch__input:checked + .switch__slider {
  background-color: #50e3c2;
}

.switch__input:focus + .switch__slider {
  box-shadow: 0 0 1px #50e3c2;
}

.switch__input:checked + .switch__slider:before {
  -webkit-transform: translateX(27px);
  transform: translateX(27px);
}

.notifications__background {
  background-color: rgba(12, 12, 12, 0.2);
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.notifications {
  position: absolute;
  height: 100vh;
  width: 290px;
  overflow-x: hidden;
  transition: 0.5s;
  right: 0;
  box-shadow: 0 1px 4px 0 rgba(54, 66, 79, 0.25),
    0 2px 20px 0 rgba(54, 66, 79, 0.35);
  background-color: white;
  overflow-y: hidden;
}

.notifications__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 70px;
  background-color: #384758;

  font-size: 16px;
  font-weight: bold;
  color: white;
  padding-left: 30px;
  padding-right: 20px;
}

.notifications__title {
  margin-left: 6px;
}

.notifications__hide {
  margin-left: auto;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.notifications__list {
  overflow-y: auto;
  height: calc(100vh - 100px);
  padding-top: 10px;
}

.message {
 padding: 10px 20px 10px 30px;
}

.message__title {
  font-size: 16px;
  font-weight: bold;
  color: #384758;
}

.message__body {
  margin-top: 5px;
  font-size: 12px;
  color: #384758;
}

.message__time {
  margin-top: 10px;
  font-size: 12px;
  color: #a0b4c8;
}


.login-page {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(56, 71, 88, 0.9);
  width: 100%;
  height: 100vh;
}

.login-logo {
  margin-top: 10%;
}
.login {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 25px;
  width: 260px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(54, 66, 79, 0.41);
  background-color: white;
  padding: 50px 60px 30px 60px;
}

.login__title {
  font-size: 24px;
  color: #36424f;
}

.login__form-control {
  display: block;
  border-radius: 5px;
  border: solid 1px #384758;
  background-color: white;
  background-clip: padding-box;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #384758;
  margin-top: 10px;
}

.login__button {
  margin-top: 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 40px;
  border-radius: 5px;
  background-color: #5bd9f9;
  cursor: pointer;
}

.login__error {
  color: red;
  margin-top: 10px;
}

.admin {
  display: -webkit-flex;
  display: flex;
  padding-top: 20px;
}

.admin__sidebar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-right: 1px #dce3eb solid;
  min-width: 200px;
  background-color: white;
}

.sidebar__link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  font-family: Nunito, serif;
  font-size: 14px;
  font-weight: bold;
  color: #72869c;
  text-transform: uppercase;
  text-decoration: none;
  height: 50px;
}
.sidebar__link:hover {
  color: #5bd9f9;
  background-color: #f1f3f5;
}

.admin__main {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  background-color: #f9fbfc;
  padding: 0 20px;
}

.admin__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 70px;
  box-shadow: 0 1px 3px 0 rgba(54, 66, 79, 0.41);
  background-color: white;
  padding: 0 30px;
}

.admin-header__title {
  font-size: 24px;
  font-weight: bold;
  color: #36424f;
}

.admin-header__app-button {
  margin-left: auto;
}
.admin__content {
  padding: 70px 30px 60px;
}

.admin-table {
  border-collapse: collapse;
  height: calc(100vh - 390px);
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(91, 217, 249, 0.2);
  border: solid 1px #e3e7eb;
  background-color: white;
}

.admin__form-group {
  margin-top: 20px;
}

.admin__save-button {
  margin-top: 20px;
}

.admin__selectors-container {
  margin-bottom: 20px;
}

.admin__product-selector {
  margin-left: 15px;
}

