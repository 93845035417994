.chart-tab {
  height: 68px;
  min-width: 102px;
  border-radius: 10px;
  border: solid 1px #dce3eb;
  background-color: white;
  padding: 10px 15px;
  cursor: pointer;
  margin: 20px 0 0 20px;
}

.chart-tab--selected {
  border: solid 2px #5bd9f9;
}

.chart-tab__name {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #384758;
}

.chart-tab__amount {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  color: #384758;
}

.chart-tab__diff {
  display: flex;

}

.chart-tab__arrow {
    min-width: 10px
}
.chart-tab__persent {
    font-size: 12px;
    color: #384758;
    margin-left: 5px;
}
