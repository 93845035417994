.c-dropdown {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    user-select: none;

    border: solid 2px #dce3eb;
    background-color: white;
    padding: 10px;
    color: black;
}

.c-dropdown--active,
.c-dropdown:hover {
    border: solid 2px rgba(91, 217, 249, 0.5);
}

.c-dropdown__arrow {
    margin-left: auto;
    width: 20px;
    height: 20px;
}
