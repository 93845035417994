.header {
  display: flex;
  height: 70px;
  background-color: #384758;
  align-items: center;
  padding: 0 30px;
}

.header__week-selector {
  margin-left: auto;
}

.notifications-toggle {
  margin-left: 20px;
  cursor: pointer;
}

.header__logout {
  margin-left: 20px;
  margin-bottom: 7px;
  cursor: pointer;
}

.profile {
  margin-left: 10px;
}

.client-selector {
  margin-left: 2rem;
  min-width: 400px;
}

.header__admin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  text-decoration: none;
  height: 26px;
  width: 26px;
  background-color: #5BD9F9;
  border-radius: 50%;
  color: #384758;
  font-family: Nunito, serif;
  font-size: 12px;
  font-weight: bold;
  margin-top: -6px;
}
