.dashboard-products {
  width: 100%;
  padding-top: 50px;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(56, 71, 88, 0.2);
  background-color: white;
}

.dashboard-products__head {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  background-color: #f9fbfc;
  text-transform: uppercase;
  font-size: 12px;
  color: #384758;
}

.dashboard-products__product-title,
.dashboard-products__title-col,
.dashboard-products__spend-title {
  padding: 10px 15px;
}

.dashboard-products__title-col,
.dashboard-products__spend-title {
  text-align: right;
}

.dashboard-products__spend-title {
  font-weight: bold;
}

.dashboard-products__row {
  height: 72px;
  cursor: pointer;
}

.dashboard-products__row:nth-of-type(even) {
  background: #f9fbfc;
}

.dashboard-products__row:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dashboard-products__row td {
  width: 25%;
}

.dashboard-products__product-col {
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  font-size: 16px;
  color: #384758;
}

.dashboard-products__spend-col,
.dashboard-products__revenue-col,
.dashboard-products__cpv-col {
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  font-size: 24px;
  text-align: right;
  color: #384758;
}

.dashboard-products__spend-col {
  font-weight: bold;
}
