.dayparts-chart {
    display: flex;
    height: 100%;
    padding: 15px 25px;
    align-items: center;
    justify-content: space-between;
}

.dayparts-chart__list {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 180px;
}

.daypart-row {
    display: flex;
    align-items: center;
}

.daypart-row__point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.daypart-row__title {
    margin-left: 10px;
}

.daypart-row__percent {
    margin-left: auto;
}

.dayparts-chart__chart {
    flex-grow: 1;
    min-width: 0;
}
