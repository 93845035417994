.switch {
  display: flex;
  align-items: center;
}

.switch__text-box {

}

.switch__title {
  font-size: 16px;
  color: #384758;
}

.switch__description {
  font-size: 12px;
  color: #a0b4c8;
}

.switch__control {
  margin-left: 20px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 30px;
}

.switch__slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.switch__input:checked + .switch__slider {
  background-color: #50e3c2;
}

.switch__input:focus + .switch__slider {
  box-shadow: 0 0 1px #50e3c2;
}

.switch__input:checked + .switch__slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}
