.dashboard {
    padding: 30px 30px 0;
}

.dashboard__dashboard-chart {
    margin-top: 30px;
}

.dashboard__products {
    padding-top: 50px;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    color: #384758;
}
