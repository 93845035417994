.message {
 padding: 10px 20px 10px 30px;
}

.message__title {
  font-size: 16px;
  font-weight: bold;
  color: #384758;
}

.message__body {
  margin-top: 5px;
  font-size: 12px;
  color: #384758;
}

.message__time {
  margin-top: 10px;
  font-size: 12px;
  color: #a0b4c8;
}

