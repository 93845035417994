.ag-theme-balham .ag-root {
  border: none !important;
  border-radius: 5px;
}

.product-table {
  margin: 40px 30px;
  border-collapse: collapse;
  font-family: Nunito, sans-serif;
  height: calc(100vh - 390px);
}

.ag-header-cell {
  background-color: #f9fbfc;
}

.ag-header-cell-label .ag-header-cell-text{
  border-spacing: 0;
  font-size: 12px;
  color: #384758;
  text-transform: uppercase;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: inset 0 -1px 0 0 #dce3eb;
  border-collapse: collapse;
}

.selected-column {
  font-weight: bold;
  color: #384758;
}

.ag-row .ag-cell {
  display: flex;
  /*justify-content: center; /* align horizontal */
  align-items: center;
  font-size: 16px;
}

.ag-row-hover {
  box-shadow: 0 2px 4px 0 rgba(54, 66, 79, 0.1) !important;
  border: solid 2px rgba(91, 217, 249, 0.5) !important;
  background-color: white !important;
}

.product-table__station {
  display: flex;
  align-items: center;
}

.product-table__centered-column {
  text-align: center;
}

.product-table__right {
  text-align: right;
}

.product-table__sorter {
  margin-left: 5px;
}

.product-table__row {
  background-color: white;
}

.product-table__head td:first-child,
.product-table__row td:first-child {
  padding-left: 15px;
}

.product-table__head td:last-child,
.product-table__row td:last-child {
  padding-right: 15px;
}

.product-table__row:nth-of-type(even) {
  background: #f9fbfc;
}

.product-table__row td {
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px rgba(91, 217, 249, 0.5);
}

.product-table__navigation {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  font-size: 12px;
  color: #384758;
}

.rows-per-page {
  display: flex;
}

.rows-per-page__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border-radius: 5px;
  border: solid 1px #dce3eb;
  background-color: white;
  cursor: pointer;
}

.rows-per-page__button--active {
  border: none;
  color: white;
  background-color: #5bd9f9;
}

.rows-per-page__button:not(:first-child) {
  margin-left: 6px;
}

.rows-per-page__text {
  display: flex;
  align-items: center;
  margin-left: 3px;
}

.showing-entries__numbers {
  font-weight: bold;
}

.page {
  display: flex;
}

.page__text {
  display: flex;
  align-items: center;
}

