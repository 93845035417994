.app {
  display: flex;
  flex-direction: column;
  background-color: #ebf1f4;
  min-height: 100vh;
  position: relative;
}

.router {
  display: flex;
  flex-direction: column;
  background-color: #ebf1f4;
  /* min-height: 100vh;*/
}
.title-bar {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}


.app__product_chart {
  margin-top: 30px;
}
.chart-row {
  display: flex;
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  flex-wrap: wrap;
}

.chart-column {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 512px;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-row {
  display: flex;
  margin-top: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: auto;
}

.form-group--no-grow {
  flex-grow: 0;
}

.form-row__form-group {
  margin-left: 35px;
}

.form-label {
  font-size: 12px;
  color: #384758;
}

.form-error {
  font-size: 14px;
  color: red;
}
.form-control {
  display: block;
  border-radius: 5px;
  border: solid 1px #384758;
  background-color: white;
  background-clip: padding-box;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #384758;
  margin-top: 5px;
}

.form-control::placeholder {
  color: #a0b4c8;
}

.app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 40px;
  width: 250px;
  border-radius: 5px;
  background-color: #5bd9f9;
  cursor: pointer;
  text-decoration: none;
}


