.admin {
  display: flex;
  padding-top: 20px;
}

.admin__sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px #dce3eb solid;
  min-width: 200px;
  background-color: white;
}

.sidebar__link {
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-family: Nunito, serif;
  font-size: 14px;
  font-weight: bold;
  color: #72869c;
  text-transform: uppercase;
  text-decoration: none;
  height: 50px;
}
.sidebar__link:hover {
  color: #5bd9f9;
  background-color: #f1f3f5;
}

.admin__main {
  flex-grow: 1;
  background-color: #f9fbfc;
  padding: 0 20px;
}

.admin__header {
  display: flex;
  align-items: center;
  height: 70px;
  box-shadow: 0 1px 3px 0 rgba(54, 66, 79, 0.41);
  background-color: white;
  padding: 0 30px;
}

.admin-header__title {
  font-size: 24px;
  font-weight: bold;
  color: #36424f;
}

.admin-header__app-button {
  margin-left: auto;
}
.admin__content {
  padding: 70px 30px 60px;
}

.admin-table {
  border-collapse: collapse;
  height: calc(100vh - 390px);
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(91, 217, 249, 0.2);
  border: solid 1px #e3e7eb;
  background-color: white;
}

.admin__form-group {
  margin-top: 20px;
}

.admin__save-button {
  margin-top: 20px;
}

.admin__selectors-container {
  margin-bottom: 20px;
}

.admin__product-selector {
  margin-left: 15px;
}
