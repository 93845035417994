.footer {
  display: flex;
  height: 45px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  padding-top: 9px;
  justify-content: space-between;
  border-top: 1px solid #dce3eb;
  font-size: 12px;
  color: #384758;
}
