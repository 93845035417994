.dashboard-title {
    display: flex;
}
.dashboard-title__logo {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: white;
}

.dashboard-title__container {
    margin-left: 20px;
}

.dashboard-title__client-name {
    font-size: 44px;
    font-weight: bold;
    color: #384758;
}

.dashboard-title__page-name {
    font-size: 16px;
    color: #384758;
    padding-left: 0.25rem;
}
